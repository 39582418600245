import React, { useState, useEffect, useMemo, useRef } from 'react';
import { createClient } from '@supabase/supabase-js';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import ExtraMarkers from 'leaflet-extra-markers';
import { useTable, useSortBy } from 'react-table';

// Import marker images for default Leaflet markers
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Initialize Supabase client
const supabaseUrl = 'https://ush-supabase-u13558.vm.elestio.app';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlzcyI6InN1cGFiYXNlIiwiaWF0IjoxNzI3MjcxODQwLCJleHAiOjIwNDI2MzE4NDB9.2BwIlgb7PkauLWV73yLlKRNJs6_piIjWY2MnJmF0uHw';
const supabase = createClient(supabaseUrl, supabaseKey);

// OpenCage API key for geocoding the address
const openCageKey = 'dc0abaad2d9943ce938ee69fead70a72';

// Fix Leaflet's default icon URLs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIconRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Define a custom marker with `leaflet-extra-markers`
const searchIcon = L.ExtraMarkers.icon({
  icon: 'fa-search',
  markerColor: 'red',
  shape: 'circle',
  prefix: 'fa',
  iconColor: 'white',
});

function StormEvents() {
// password functions
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    const lastAuthTime = localStorage.getItem('lastAuthTime');
    if (lastAuthTime && Date.now() - parseInt(lastAuthTime) < 3600000) {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'HailYeah!!') {
      setIsAuthenticated(true);
      localStorage.setItem('lastAuthTime', Date.now().toString());
    } else {
      alert('Incorrect password. Please try again.');
    }
  };
  
  // Utility functions
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 3959; // Radius of the Earth in miles
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in miles
  }

  function calculateBearing(lat1, lon1, lat2, lon2) {
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const y = Math.sin(dLon) * Math.cos(lat2 * (Math.PI / 180));
    const x = Math.cos(lat1 * (Math.PI / 180)) * Math.sin(lat2 * (Math.PI / 180)) -
              Math.sin(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.cos(dLon);
    const bearing = (Math.atan2(y, x) * 180 / Math.PI + 360) % 360;
    return bearing;
  }

  function getDirection(bearing) {
    const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    const index = Math.round(bearing / 22.5) % 16;
    return directions[index];
  }
  
  // Function to calculate opacity based on event age
  const calculateOpacity = (eventDate) => {
    const ageInYears = (new Date() - new Date(eventDate)) / (1000 * 60 * 60 * 24 * 365);
    if (ageInYears <= 2) return 1; // 100% opacity for events 2 years or newer
    if (ageInYears >= 10) return 0.4; // 40% opacity for events 10 years or older
    // Linear interpolation for events between 2 and 10 years old
    return 1 - (0.6 * (ageInYears - 2) / 8);
  };
  
  const [address, setAddress] = useState('');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [radius, setRadius] = useState(1609.34);
  const [yearsBack, setYearsBack] = useState(1);
  const [error, setError] = useState(null);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [autocompleteEnabled, setAutocompleteEnabled] = useState(false);
  const [map, setMap] = useState(null);
  const searchMarkerRef = useRef(null);

  const radiusOptions = [
    { label: '1 mile', value: 1609.34 },
    { label: '2 miles', value: 3218.69 },
    { label: '5 miles', value: 8046.72 },
    { label: '10 miles', value: 16093.4 },
    { label: '20 miles', value: 32186.9 }
  ];

  // ... rest of the component code (handleSearch, handleAddressChange, useEffects, etc.) remains the same

const columns = useMemo(
  () => [
    {
      Header: 'Event Type',
      accessor: 'EVENT_TYPE',
    },
    {
      Header: 'Date',
      accessor: 'BEGIN_DATE_TIME',
    },
    {
      Header: 'Timezone',
      accessor: 'CZ_TIMEZONE',
    },
    {
      Header: 'State',
      accessor: 'STATE',
    },
    {
      Header: 'Magnitude',
      accessor: 'MAGNITUDE',
      Cell: ({ value, row }) => `${value || 'N/A'} ${row.original.MAGNITUDE_TYPE || ''}`,
    },
    {
      Header: 'Distance',
      accessor: (row) => calculateDistance(coordinates.lat, coordinates.lng, row.BEGIN_LAT, row.BEGIN_LON),
      Cell: ({ value }) => `${value.toFixed(2)} miles`,
    },
    {
      Header: 'Direction',
      accessor: (row) => {
        const bearing = calculateBearing(coordinates.lat, coordinates.lng, row.BEGIN_LAT, row.BEGIN_LON);
        return getDirection(bearing);
      },
    },
  ],
  [coordinates]
);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: events }, useSortBy);


  // Function to handle search action when the user searches for events around an address
  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      // Get coordinates from the address using OpenCage API
      const geoResponse = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          address
        )}&key=${openCageKey}`
      );
      const geoData = await geoResponse.json();
      // Handle case where no location is found
      if (geoData.results.length === 0) {
        setError('No location found. Please try another address.');
        setLoading(false);
        return;
      }

      // Extract latitude and longitude from geocoding response
      const lat = parseFloat(geoData.results[0].geometry.lat);
      const lng = parseFloat(geoData.results[0].geometry.lng);
      setCoordinates({ lat, lng }); // Save the coordinates to state

      // Calculate start and end dates for the search
      const endDate = new Date();
      const startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - yearsBack);

      // Prepare parameters for the Supabase RPC function
      const params = {
        lat: parseFloat(lat),
        lon: parseFloat(lng),
        radius: parseFloat(radius),
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString()
      };

      // Fetch storm events from Supabase
      const { data, error, count } = await supabase.rpc('get_storm_events_nearby', params)
        .range(0, 999) // Fetch up to 1000 results
        .select('*', { count: 'exact' });

      // Handle potential error from Supabase call
      if (error) {
        setError(`Error fetching data: ${error.message}`);
        throw error;
      }

      setEvents(data); // Save events to state
      setTotalCount(count); // Save total count of events
    } catch (error) {
      // Handle any errors that occur during the fetch
      setError('An error occurred while fetching storm events. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Handle input change and autocomplete suggestions for the address field
  const handleAddressChange = async (e) => {
    const input = e.target.value;
    setAddress(input);

    // Fetch suggestions from OpenCage API if autocomplete is enabled
    if (autocompleteEnabled && input.length > 2) {
      try {
        const response = await fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(input)}&key=${openCageKey}&limit=5&autocomplete=1`
        );
        const data = await response.json();
        setAddressSuggestions(data.results.map(result => result.formatted));
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    } else {
      setAddressSuggestions([]);
    }
  };

  // Handle selection of an address from autocomplete suggestions
  const handleSuggestionClick = (suggestion) => {
    setAddress(suggestion);
    setAddressSuggestions([]);
  };

  // useEffect for initializing and updating the map when coordinates change
  useEffect(() => {
    if (coordinates && !map) {
      const newMap = L.map('map').setView([coordinates.lat, coordinates.lng], 10);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(newMap);

      // Create the search marker and store its reference in `searchMarkerRef`
      searchMarkerRef.current = L.marker([coordinates.lat, coordinates.lng], { icon: searchIcon })
        .addTo(newMap)
        .bindPopup('Search Address');

      setMap(newMap);
    } else if (map && coordinates) {
      map.setView([coordinates.lat, coordinates.lng], 10);

      // Update the search marker's position if it already exists
      if (searchMarkerRef.current) {
        searchMarkerRef.current.setLatLng([coordinates.lat, coordinates.lng]);
      } else {
        // Otherwise, create a new search marker
        searchMarkerRef.current = L.marker([coordinates.lat, coordinates.lng], { icon: searchIcon })
          .addTo(map)
          .bindPopup('Search Address');
      }
    }
  }, [coordinates, map]);

// Update the useEffect hook that adds event markers to the map
  useEffect(() => {
    if (map && events.length > 0) {
      // Remove all event markers, but preserve the search marker
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer !== searchMarkerRef.current) {
          map.removeLayer(layer);
        }
      });

      // Add new markers for storm events with magnitude labels and age-based opacity
      events.forEach(event => {
        const magnitude = event.MAGNITUDE || 'N/A';
        const magnitudeType = event.MAGNITUDE_TYPE || '';
        const opacity = calculateOpacity(event.BEGIN_DATE_TIME);
        
        // Create a custom icon with magnitude label and age-based opacity
        const customIcon = L.divIcon({
          className: 'custom-div-icon',
          html: `
            <div style="
              background-color: rgba(51, 136, 255, ${opacity}); 
              width: 30px; 
              height: 30px; 
              border-radius: 50%; 
              display: flex; 
              justify-content: center; 
              align-items: center; 
              color: white; 
              font-weight: bold;
            ">
              ${magnitude}
            </div>
          `,
          iconSize: [30, 30],
          iconAnchor: [15, 15]
        });

        L.marker([event.BEGIN_LAT, event.BEGIN_LON], { icon: customIcon })
          .addTo(map)
          .bindPopup(`
            <b>${event.EVENT_TYPE}</b><br>
            Date: ${new Date(event.BEGIN_DATE_TIME).toLocaleString()}<br>
            Magnitude: ${magnitude} ${magnitudeType}
          `);
      });

      // Fit bounds to include all markers
      const bounds = L.latLngBounds(events.map(event => [event.BEGIN_LAT, event.BEGIN_LON]));
      map.fitBounds(bounds);
    }
  }, [events, map]);









  if (!isAuthenticated) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <form onSubmit={handlePasswordSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Enter Password to View Storm Events</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ margin: '10px 0', padding: '5px' }}
          />
          <button type="submit" style={{ padding: '5px 10px' }}>Submit</button>
        </form>
      </div>
    );
  }



  return (
    <div>
      <h2>Search for Storm Events Near an Address</h2>
      
      {/* Form section for radius and years back */}
      <div style={{ marginBottom: '10px' }}>
        <select
          value={radius}
          onChange={(e) => setRadius(Number(e.target.value))}
          style={{ marginRight: '10px' }}
        >
          {radiusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <input
          type="number"
          value={yearsBack}
          onChange={(e) => setYearsBack(e.target.value === '' ? '' : parseInt(e.target.value))}
          placeholder="Years ago"
          min="1"
          max="99"
          style={{ width: '60px', marginRight: '5px' }}
        />
        <label htmlFor="yearsInput" style={{ marginRight: '10px' }}>years ago</label>
      </div>

      {/* Address input and autocomplete */}
      <div style={{ position: 'relative', marginBottom: '10px' }}>
        <input
          type="text"
          value={address}
          onChange={handleAddressChange}
          placeholder="Enter address"
          style={{ width: '300px', marginRight: '10px' }}
        />
        <label>
          <input
            type="checkbox"
            checked={autocompleteEnabled}
            onChange={() => setAutocompleteEnabled(!autocompleteEnabled)}
          />
          Enable Autocomplete
        </label>
        {autocompleteEnabled && addressSuggestions.length > 0 && (
          <ul style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 1,
            listStyle: 'none',
            padding: 0,
            border: '1px solid #ccc',
            backgroundColor: 'white',
            width: '300px',
            maxHeight: '150px',
            overflowY: 'auto'
          }}>
            {addressSuggestions.map((suggestion, index) => (
              <li 
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                style={{ padding: '5px', cursor: 'pointer' }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Search button */}
      <button onClick={handleSearch} disabled={loading || yearsBack === ''}>
        {loading ? 'Searching...' : 'Search'}
      </button>

      {/* Map container */}
      <div id="map" style={{ height: '400px', width: '100%', marginTop: '20px' }}></div>

      {/* Error and status messages */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {coordinates && (
        <p>
          Search Location: Latitude: {coordinates.lat.toFixed(4)}, Longitude: {coordinates.lng.toFixed(4)}
        </p>
      )}





{/* React Table */}
{rows.length > 0 && (
  <div style={{ marginTop: '20px' }}>
    <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                borderBottom: 'solid 3px red',
                background: 'aliceblue',
                color: 'black',
                fontWeight: 'bold',
                padding: '10px',
              }}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      border: 'solid 1px gray',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <p>Showing {rows.length} of {totalCount} events</p>
  </div>
)}
    </div>
  );
}

export default StormEvents;